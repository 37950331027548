import React, { useState } from 'react';

const SearchComponent = ({ onProductsSelect }) => {
  const [product1, setProduct1] = useState('');
  const [product2, setProduct2] = useState('');
  const [product3, setProduct3] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onProductsSelect([product1, product2, product3]);
  };

  return (
    <div>
      <h2>Products for Comparison</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>1:</label>
          <input
            type="text"
            value={product1}
            onChange={(e) => setProduct1(e.target.value)}
            placeholder="product name"
          />
        </div>
        <div>
          <label>2:</label>
          <input
            type="text"
            value={product2}
            onChange={(e) => setProduct2(e.target.value)}
            placeholder="product name"
          />
        </div>
        <div>
          <label>3:</label>
          <input
            type="text"
            value={product3}
            onChange={(e) => setProduct3(e.target.value)}
            placeholder="product name"
          />
        </div>
        <button type="submit">Compare Products</button>
      </form>
    </div>
  );
};

export default SearchComponent;

