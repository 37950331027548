import React, { useState } from 'react';

const CategoriesComponent = ({ onCategorySelect }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const categories = ['Electronics', 'Home Appliances', 'Fashion', 
'Books', 'Sports'];

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    onCategorySelect(category);
  };

  return (
    <div>
      <h2>Select a Category</h2>
      <select value={selectedCategory} onChange={handleCategoryChange}>
        <option value="">-- Select Category --</option>
        {categories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CategoriesComponent;

