import React, { useState } from 'react';
import CategoriesComponent from './CategoriesComponent';
import SearchComponent from './SearchComponent';
import ComparisonComponent from './ComparisonComponent';
import './App.css';

function App() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Handle product selection
  const handleProductsSelect = (products) => {
    setSelectedProducts(products);
  };

  return (
    <div className="App">
      <h1>Product Comparison</h1>
      
      {/* Category Selection */}
      <CategoriesComponent onCategorySelect={handleCategorySelect} />

      {/* Product Search appears after category is selected */}
      {selectedCategory && <SearchComponent onProductsSelect={handleProductsSelect} />}

      {/* Display Comparison Table once products are selected */}
      {selectedProducts.length > 0 && <ComparisonComponent products={selectedProducts} />}
    </div>
  );
}

export default App;
