import React from 'react';

// Sample data with unique identifiers for Amazon, Argos, and Currys
const products = [
  {
    name: 'iPhone 16 Pro',
    displaySize: '6.1 inches',
    resolution: '2556 x 1179',
    eBayPrice: '$1,099',  // Made cheaper
    amazonPrice: '$1,199',  // Made cheaper
    argosPrice: '£1,249',   // Slightly more expensive
    currysPrice: '£1,249',  // Slightly more expensive
    amazonLink: 'https://amzn.to/3ZKLge7',
    argosLink: 'https://www.argos.co.uk/product/ARGOSITEMID16PRO',
    currysLink: 'https://www.currys.co.uk/gbuk/PRODUCTCODE'
  },
  {
    name: 'iPhone 14 Pro',
    displaySize: '6.1 inches',
    resolution: '2556 x 1179',
    eBayPrice: '$949',    // Made cheaper
    amazonPrice: '$969',    // Made cheaper
    argosPrice: '£999',     // Slightly more expensive
    currysPrice: '£1,005',  // Slightly more expensive
    amazonLink: 'https://amzn.to/3ZKLge7',
    argosLink: 'https://www.argos.co.uk/product/ARGOSITEMID14PRO',
    currysLink: 'https://www.currys.co.uk/gbuk/PRODUCTCODE'
  },
  {
    name: 'Samsung Galaxy S24 Ultra',
    displaySize: '6.8 inches',
    resolution: '3088 x 1440',
    eBayPrice: '$1,005',  // Made cheaper
    amazonPrice: '$1,025',  // Made cheaper
    argosPrice: '£1,049',   // Slightly more expensive
    currysPrice: '£1,065',  // Slightly more expensive
    amazonLink: 'https://amzn.to/3ZKLge7',
    argosLink: 'https://www.argos.co.uk/product/ARGOSITEMIDS24ULTRA',
    currysLink: 'https://www.currys.co.uk/gbuk/PRODUCTCODE'
  }
];

function ComparisonTable() {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>Phone</th>
          <th>Display Size</th>
          <th>Resolution</th>
          <th>eBay Price</th>
          <th>Amazon Price</th>
          <th>Argos Price</th>
          <th>Currys Price</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td>{product.name}</td>
            <td>{product.displaySize}</td>
            <td>{product.resolution}</td>
            <td>
              {product.eBayPrice}{' '}
              <a href={product.eBayLink} target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                Buy on eBay
              </a>
            </td>
            <td>
              {product.amazonPrice}{' '}
              <a href={product.amazonLink} target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                Buy on Amazon
              </a>
            </td>
            <td>
              {product.argosPrice}{' '}
              <a href={product.argosLink} target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                Buy at Argos
              </a>
            </td>
            <td>
              {product.currysPrice}{' '}
              <a href={product.currysLink} target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                Buy at Currys
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ComparisonTable;
